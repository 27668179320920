import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

function AboutPage({ data }) {
  const { allTeamJson } = data
  return (
    <Layout>
      <SEO title="About" />
      <main className="text-gray-800">
        <div className="container mb-12 lg:mb-32">
          <div className="relative shadow">
            <div className="bg-white bg-opacity-90 max-w-2xl mx-auto p-4 lg:p-12 relative z-10">
              <p className="mb-4">
                L.A. Art Exchange was established in 1987 by Stuart and Jayne
                Zehngut. New to California, but not to framing, their goal was
                to take the knowledge they learned from their Ohio
                do-it-yourself picture framing business and establish a high
                quality, superior service, custom framing store that would offer
                the best designs and frames in the industry.
              </p>
              <p className="mb-4">
                They grew from a small mom and pop operation to an industry
                leading business that serves clients internationally.{' '}
              </p>
              <p className="mb-4">
                For 29 years, L.A. Art Exchange made excellent frames at their
                Broadway shop. In 2016, they made the move to the current
                location on Santa Monica Blvd. Their new digs enlarged the
                design showroom and production facility allowing the company to
                continue to grow and take on bigger and more complicated
                projects.
              </p>
              <p>
                After 33 years in business, it is still a learning experience
                and a pleasure to provide exceptional design and service not
                only to our original customers, but their children, and in some
                cases, their grandchildren.
              </p>
            </div>
            <Img
              className="top-0 left-0 w-full h-full"
              fluid={data.outside.childImageSharp.fluid}
              style={{ position: `absolute` }}
            />
          </div>
        </div>

        <div className="container">
          <h2 className="text-center text-purple-300 text-lg lg:text-xl mb-6 lg:mb-12">
            Our Team
          </h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-1">
            {allTeamJson.nodes.map(item => (
              <li className="hover-container relative" key={item.name}>
                <div className="hover-content hidden absolute top-0 left-0 p-4 z-10 w-full h-full bg-black bg-opacity-75 text-white">
                  <div className="capitalize mb-2 font-bold">
                    {item.name} - {item.position}
                  </div>
                  <div
                    className="team-hover-content mb-2"
                    dangerouslySetInnerHTML={{ __html: item.bio }}
                  ></div> 
                  {item.email !== '' && (
                    <a
                      className="text-md underline capitalize"
                      href={'mailto:' + item.email}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Email {item.name}
                    </a>
                  )}
                </div>
                <Img alt="" fluid={data[item.name].childImageSharp.fluid} />
              </li>
            ))}
          </ul>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allTeamJson {
      nodes {
        name
        position
        bio
        email
      }
    }

    outside: file(relativePath: { eq: "outside.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000, grayscale: true) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jayne: file(relativePath: { eq: "team/jayne.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    adam: file(relativePath: { eq: "team/adam.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mike: file(relativePath: { eq: "team/mike.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chris: file(relativePath: { eq: "team/chris.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    marvin: file(relativePath: { eq: "team/marvin.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sarah: file(relativePath: { eq: "team/sarah.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    brian: file(relativePath: { eq: "team/brian.jpeg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    richard: file(relativePath: { eq: "team/richard.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    athena: file(relativePath: { eq: "team/athena.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default AboutPage
